import pptxgen from 'pptxgenjs';
import pptLogo from '@/assets/img/ppt-logo.png';
import pptBg from '@/assets/img/ppt-bg.jpg';

export default {
  methods: {
    async handleExportPPT(list) {
      if (list.length === 0) {
        this.$message.warning('请选择产品');
        return;
      }
      const loading = this.$baseLoading();
      const pres = new pptxgen(); // eslint-disable-line
      let slide = pres.addSlide();
      slide.background = { path: pptBg };
      slide.addImage({
        path: pptLogo,
        type: 'cover',
        w: 1.3,
        h: 1.3,
        x: '28%',
        y: '40%',
      });
      slide.addText('产品展示', {
        x: '43%',
        y: '40%',
        h: 1.3,
        color: '6786B8',
        fontFace: 'Microsoft YaHei',
        fontSize: 44,
      });
      for (let i = 0; i <= list.length - 1; i += 1) {
        const target = list[i];
        const response = await this.$baseHttp.get(`/goods/detail?id=${target.id}`, {
          noLoading: true,
        });
        slide = pres.addSlide();
        slide.background = { path: pptBg };
        slide.addImage({
          path: pptLogo,
          type: 'cover',
          w: 0.5,
          h: 0.5,
          x: '2%',
          y: '2%',
        });
        slide.addText(response.data.title, {
          x: '8%',
          y: '6%',
          color: '6786B8',
          fontFace: 'Microsoft YaHei',
          fontSize: 18,
        });
        slide.addShape(pres.shapes.LINE, {
          x: '8%',
          y: '10%',
          w: '30%',
          h: 0.0,
          line: {
            color: '6786B8',
            width: 1,
            dashType: 'solid',
          },
        });
        slide.addShape(pres.shapes.LINE, {
          x: '65%',
          y: '90%',
          w: '30%',
          h: 0.0,
          line: {
            color: '6786B8',
            width: 1,
            dashType: 'solid',
          },
        });
        slide.addText('把一件事情做到极致', {
          x: '65%',
          y: '90%',
          w: '30%',
          h: '8%',
          color: '6786B8',
          fontFace: 'Microsoft YaHei',
          fontSize: 12,
          align: 'right',
        });
        let str = response.data.content.replace(/<\/p>/ig, '\n');
        str = str.replace(/<[^<>]+>| /g, '');
        str = str.replace(/&nbsp;/g, ' ');
        str = str.replace(/\n/g, '-||-');
        const content = str.split('-||-').filter((r) => r !== '').map((text) => ({
          text: `${text}\n`,
          options: {
            color: '6786B8',
            fontSize: 10,
            fontFace: 'Microsoft YaHei',
          },
        }));
        // slide.addText(response.data.title, {
        //   x: '8%',
        //   y: '30%',
        //   w: '45%',
        //   color: '6786B8',
        //   fontFace: 'Microsoft YaHei',
        //   fontSize: 18,
        // });
        slide.addText(content, {
          x: '8%',
          y: '35%',
          h: '40%',
          w: '50%',
          lineSpacing: 20,
          // valign: 'top',
        });
        if (response.data.gallery && response.data.gallery.length > 0) {
          slide.addImage({
            path: response.data.gallery[0].file,
            type: 'cover',
            x: '60%',
            y: '20%',
            w: 3.3,
            h: 3.3,
          });
        }
      }
      pres.writeFile({ fileName: '产品展示' });
      loading.close();
    },
  },
};
